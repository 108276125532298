import React, { useContext } from "react";
import { ProductContext } from "../contexts/ProductProvider";
import Product from "./Product";
import style from "./ProductList.module.css";

const ProductList = () => {
  const [products] = useContext(ProductContext);

  console.log(style);
  return (
    <div className={style.list}>
      {products.map((product) => (
        <Product
          key={product.id}
          id={product.id}
          nome={product.nome}
          descricao={product.descricao}
          preco={product.preco}
          imagem={product.imagens[0].imagem}
          slug={product.slug}
        />
      ))}
    </div>
  );
};

export default ProductList;
