import React, { useContext } from "react";
import { ProductContext } from "../contexts/ProductProvider";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import style from "./ProductInfo.module.css";

const ProductInfo = () => {
  const [products] = useContext(ProductContext);
  const params = useParams();

  if (products.length > 0) {
    const product = products.find((element) => element.slug === params.slug);
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{product.nome} - Rosa Verão React</title>
        </Helmet>
        <div className={style.container}>
          <div className="product-image">
            <Link to="/">
              <img
                src="https://www.rosaverao.com.br/public/images/logo_rosa_verao_small.png"
                alt=""
                className="product-logo"
              />
            </Link>
            <img
              src={
                "https://www.rosaverao.com.br/public/images/produtos/thumb/" +
                product.imagens[0].imagem
              }
              alt=""
              className="product-pic"
            />
            <div className="dots">
              <a href="#!" className="active">
                <i>1</i>
              </a>
              <a href="#!">
                <i>2</i>
              </a>
              <a href="#!">
                <i>3</i>
              </a>
              <a href="#!">
                <i>4</i>
              </a>
            </div>
          </div>

          <div className="product-details">
            <header>
              <h1 className="title">{product.nome}</h1>
              <span className="colorCat">mint green</span>
              <div className="price">
                <span className="current">${product.preco}</span>
              </div>
              <div className="rate">
                <a href="#!" className="active">
                  ★
                </a>
                <a href="#!" className="active">
                  ★
                </a>
                <a href="#!" className="active">
                  ★
                </a>
                <a href="#!">★</a>
                <a href="#!">★</a>
              </div>
            </header>
            <article>
              <h5>Descrição</h5>
              <p dangerouslySetInnerHTML={{ __html: product.descricao }}></p>
            </article>
            <div className="controls">
              <div className="color">
                <h5>color</h5>
                <ul>
                  <li>
                    <a href="#!" className="colors color-bdot1 active">
                      {" "}
                    </a>
                  </li>
                  <li>
                    <a href="#!" className="colors color-bdot2">
                      {" "}
                    </a>
                  </li>
                  <li>
                    <a href="#!" className="colors color-bdot3">
                      {" "}
                    </a>
                  </li>
                  <li>
                    <a href="#!" className="colors color-bdot4">
                      {" "}
                    </a>
                  </li>
                  <li>
                    <a href="#!" className="colors color-bdot5">
                      {" "}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="size">
                <h5>Tamanho</h5>
                <a href="#!" className="option">
                  (UK 8)
                </a>
              </div>
              <div className="qty">
                <h5>qty</h5>
                <a href="#!" className="option">
                  (1)
                </a>
              </div>
            </div>
            <div className="footer">
              <button type="button">
                <img
                  src="https://co0kie.github.io/codepen/nike-product-page/cart.png"
                  alt=""
                />
                <span>+ Carrinho</span>
              </button>
              <a href="#!">
                <img
                  src="https://co0kie.github.io/codepen/nike-product-page/share.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  } else return <div></div>;
};

export default ProductInfo;
