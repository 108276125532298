import React, { useState } from "react";

const Contact = () => {
  const [nome, setNome] = useState();
  const [nomeError, setNomeError] = useState();
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const [mensagem, setMensagem] = useState();
  const [mensagemError, setMensagemError] = useState();
  const [isMessageSent, setIsMessageSent] = useState();

  const handleNome = (e) => {
    setNome(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleMensagem = (e) => {
    setMensagem(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // limpa os erros
    setNomeError(null);
    setEmailError(null);
    setMensagemError(null);
    setIsMessageSent(null);

    fetch("http://contato.rosaverao.com.br:8080/home/contato/", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ nome, email, mensagem }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((data) => {
        if (data.errors) {
          if (data.errors.nome) setNomeError(data.errors.nome);
          if (data.errors.email) setEmailError(data.errors.email);
          if (data.errors.mensagem) setMensagemError(data.errors.mensagem);
        } else {
          if (data.return === true) {
            setIsMessageSent(true);
          }
        }
      });
    });
  };

  return (
    <div>
      <h1>Contato</h1>
      <form onSubmit={handleSubmit}>
        <p>
          Nome:
          <br />
          <input type="text" onChange={handleNome} name="nome" />
          {nomeError ? <span>{nomeError}</span> : null}
        </p>
        <p>
          Email
          <br />
          <input type="text" onChange={handleEmail} name="email" />
          {emailError ? <span>{emailError}</span> : null}
        </p>
        <p>
          Mensagem
          <br />
          <textarea name="mensagem" onChange={handleMensagem}></textarea>
          {mensagemError ? <span>{mensagemError}</span> : null}
        </p>
        <p>
          <button type="submit">Enviar</button>
          {isMessageSent === true ? (
            <span>Mensagem enviada com sucesso</span>
          ) : null}
        </p>
      </form>
    </div>
  );
};

export default Contact;
