import React from "react";
import ProductList from "./components/ProductList";
import { ProductProvider } from "./contexts/ProductProvider";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ProductInfo from "./pages/ProductInfo";
import { Helmet } from "react-helmet";
import Contact from "./pages/Contact";

const App = () => {
  return (
    <BrowserRouter>
      <ProductProvider>
        <Switch>
          <Route path="/" exact>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Rosa Verão React</title>
              <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="App">
              <h1>Rosa Verão React</h1>
              <ProductList />
            </div>
          </Route>
          <Route path="/product/info/:slug">
            <ProductInfo></ProductInfo>
          </Route>
          <Route path="/contact/" exact>
            <Contact />
          </Route>
        </Switch>
      </ProductProvider>
    </BrowserRouter>
  );
};
export default App;
