import React from "react";
import { Link } from "react-router-dom";
import StripTags from "striptags";
import style from "../components/ProductList.module.css";

const Product = ({ id, nome, descricao, preco, imagem, slug }) => {
  return (
    <div className={style.product}>
      <img
        alt="shoes1"
        src={
          "https://www.rosaverao.com.br/public/images/produtos/thumb/" + imagem
        }
        width="200"
        height="300"
      />
      <div>
        <h2>{nome}</h2>
        <p className={style.price}>{preco}</p>
        <p className={style.descr}>
          {StripTags(descricao).substring(0, 50)}...
        </p>
        <br />
        <Link to={"/product/info/" + slug}>
          <p>Adicionar</p>
        </Link>
      </div>
    </div>
  );
};

export default Product;
