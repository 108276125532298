import React, { useState, createContext, useEffect } from "react";

export const ProductContext = createContext();

export const ProductProvider = (props) => {
  const [products, setProducts] = useState([]);

  // Loads the product list on start up
  useEffect(() => {
    fetch("https://www.rosaverao.com.br/home/reactdev/")
      .then((response) => response.json())
      .then((json) => {
        setProducts(json);
      });
  }, []);

  return (
    <ProductContext.Provider value={[products, setProducts]}>
      {props.children}
    </ProductContext.Provider>
  );
};
